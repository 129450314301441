@import "fonts.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
/* html{
  font-size: 62.5%;
} */
a {
  text-decoration: none;
  text-underline: none;
  color: white;
}

body {
  background-color: #191a1d;
  scroll-behavior: smooth;
  color: white;
  font-family: "Oxygen", sans-serif;
  font-weight: 300;
  box-sizing: border-box;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 1.7rem;
}

.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

body::-webkit-scrollbar {
  width: 0.2em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

@media (max-width: 800px) {
  html {
    font-size: 14px;
  }
}
