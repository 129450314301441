.slider-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .slide {
    width: 50rem;
    height: 30rem;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .title {
    font-family: "Exo", sans-serif;
    position: absolute;
    z-index: -1;
    top: 8rem;
    left: -6.7rem;
    font-size: 6rem;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
  }

  .title-border {
    font-family: "Exo", sans-serif;
    position: absolute;
    color: transparent;
    top: 8rem;
    left: -6.7rem;
    font-size: 6rem;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    letter-spacing: 1px;
  }

  .title,
  .title-border {
    transition: all 0.5s ease-out;
    transform: translateX(-10rem);
    opacity: 0;
    transition-delay: 0.5s;
  }

  .container-subtitile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16.5rem;
    left: -6.2rem;
    opacity: 0;
    transform: translateX(-10rem);
    transition: all 0.5s ease-out;
    transition-delay: 0.7s;
  }

  .subtitle {
    background-color: #191a1d82;
    font-size: 1.4rem;
    padding: 0rem 1rem 0.2rem 1rem;
    margin-left: 1rem;
    line-height: 1.35rem;
  }

  .redline {
    width: 0;
    height: 2px;
    background-color: #58254f;
    transition: all 0.6s ease-out;
    transition-delay: 0.7s;
  }

  .revield {
    .title,
    .title-border,
    .container-subtitile {
      opacity: 1;
      transform: translateX(0);
    }

    .redline {
      width: 2rem;
    }
  }
}

@media (max-width: 1025px) {
  .slider-container {
    .title,
    .title-border {
      left: -4rem;
    }

    .container-subtitile {
      left: -2.5rem;
    }
  }
}

@media (max-width: 800px) {
  .slider-container {
    .title,
    .title-border {
      left: -2rem;
    }

    .container-subtitile {
      left: -1.5rem;
    }
  }
}

@media (max-width: 600px) {
  .slider-container {
    .slide {
      width: 100%;
      height: 100%;
    }

    .title,
    .title-border {
      font-size: 5rem;
      left: 1rem;
      top: 42%;
      color: white;
      -webkit-text-stroke-color: white;
    }

    .container-subtitile {
      left: 1.5rem;
      top: 56%;
    }
  }
}
