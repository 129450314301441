.hero {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &-img {
    background-size: cover;
    background-image: url("https://www.api.benjamincopinet.fr/wp-content/uploads/2021/01/Photo-enfermement.jpg");
    background-position: center;

    width: 100%;
    height: 100%;

    -webkit-animation: heroAnim 15s ease 1s forwards;
    animation: heroAnim 15s ease 1s forwards;
  }
}
