.img{
  width: 100%;
  background-size:cover;
  height: 100%;
  background-position: center;
  cursor:zoom-in;
}

.responsive{
  height: 30vh;
 
}

// .pswp__bg{
//   opacity: 0.5;
// }