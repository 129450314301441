$grey-primary: grey;
$grey-secondary: rgb(160, 160, 160);

.contact {
  padding: 7em 7rem 3rem 7rem;
  flex-direction: column;
  position: relative;
  color: grey;

  &-title {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 3.2rem;
    color: white;
  }

  &-content {
    width: 100%;
    background: #181819ee;
    border-radius: 3px;
  }

  .react-icons {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }

  &-form {
    flex-direction: column;
    width: 60%;
    padding: 3rem 4rem;
  }

  &-left {
    height: 100%;
    width: 50%;
    border-right: 0.5px solid $grey-primary;
    font-size: 1.5rem;
    padding: 5rem 3rem;
  }

  &-details {
    h2 {
      margin-bottom: 2rem;
      font-size: 2rem;
      color: $grey-secondary;
    }

    p {
      margin-top: 1rem;
    }
  }

  &-button {
    margin-top: 2rem;
    background-color: #0a0a0c00;
    width: 10rem;
    height: 3rem;
    color: grey;
    border-radius: 3px;
    font-family: inherit;
    border: 0.5px solid $grey-primary;
    cursor: pointer;
  }

  &-backround {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("https://www.api.benjamincopinet.fr/wp-content/uploads/2019/11/benjamincopinet.fr-paysages-003.webp");
    background-size: cover;
    background-position: center;
    // filter: grayscale(1);
    opacity: 0.8;
    z-index: -1;
  }
}

@media (max-width: 1023px) {
  .contact {
    &-content {
      flex-direction: column;
    }

    &-left {
      border: none;
      width: 100%;
    }

    &-form {
      flex-direction: column;
      width: 100%;
      padding: 3rem 4rem;
    }
  }
}

@media (max-width: 800px) {
  .contact {
    height: auto;
  }
}

@media (max-width: 600px) {
  .contact {
    padding: 5em 1rem 3rem 1rem;
    height: auto;

    &-left,
    &-form {
      height: auto;
      padding: 2rem 1.5rem;
    }
    &-title {
      font-size: 3rem;
    }
  }
}
